'use client'

import { Box, IconButton } from '@chakra-ui/react'
import GrandPopover from 'ui/common/GrandPopover'
import { type ReactElement, type ReactNode, useCallback, useRef } from 'react'
import useGetCachedRequest from 'api/utils/next/useGetCachedRequest'
import type { AssistantDTO } from 'ecosystem'
import { CgClose } from 'react-icons/cg'
import { Avatar } from '../Avatar'
import { useAssistantSize } from '../useAssistantSize'
import { type AssistantContextState, type AssistantTeaserMode } from '../types'
import { BG_GRADIENT } from '../constants'
import { useTeaser } from '../useTeaser'
import { AssistantTeaser } from '../AssistantTeaser'
import Main from './Main'

interface AssistantProps {
  paths: {
    chat: string
    settings: string
    thread: string
    products: string
    textToSpeech: string
  }
  components?: AssistantContextState['components']
  teaser?: {
    mobileMode?: AssistantTeaserMode
    desktopMode?: AssistantTeaserMode
    delay?: number
    content: ReactNode
  }
}

export const AssistantV2 = (props: AssistantProps) => {
  const { paths, components, teaser } = props
  const { data: assistantSettings, isLoading } = useGetCachedRequest<AssistantDTO>(paths.settings)
  const inputRef = useRef<HTMLInputElement>(null)
  const { isExpanded, closeAssistant: onClose, isOpen, openAssistant } = useAssistantSize()

  const { isOpenTeaser, onTeaserSuccess, onCloseTeaserHint, teaserMode } = useTeaser({
    openAssistant,
    ...teaser
  })

  const onOpen = useCallback(() => {
    openAssistant()
    onTeaserSuccess()
  }, [onTeaserSuccess, openAssistant])

  const onToggle = useCallback(() => {
    if (isOpen) {
      onClose()
    } else {
      onOpen()
    }
  }, [isOpen, onClose, onOpen])

  if (isLoading) {
    return null
  }

  if (!assistantSettings) {
    return null
  }

  const renderMainButton = () => (
    <Box bottom="20px" left="20px" position="fixed" zIndex={3}>
      <IconButton
        className="ai-widget__main-btn"
        rounded="full"
        overflow="hidden"
        aria-label={assistantSettings.name ?? 'AI assistant'}
        bg="transparent"
        bgGradient={isOpen ? BG_GRADIENT : 'red'}
        color="text.light"
        p={0}
        icon={
          isOpen ? (
            <CgClose size={25} />
          ) : (
            <Avatar
              w={12}
              h={12}
              imageProps={{
                src: assistantSettings.imgUrl,
                objectFit: 'contain',
                w: 'full',
                h: 'full',
                sizes: '100px'
              }}
            />
          )
        }
        minW={undefined}
        onClick={onToggle}
        size="lg"
        w={12}
      />
    </Box>
  )

  const renderPopoverTarget = (): ReactElement => {
    if (teaserMode !== 'hint' || components?.teaser?.isHidden) {
      return renderMainButton()
    }

    const defaultRender = (
      <AssistantTeaser
        isOpen={isOpenTeaser}
        onClose={onCloseTeaserHint}
        target={renderMainButton()}
        {...components?.teaser?.props}>
        {teaser?.content}
      </AssistantTeaser>
    )

    return components?.teaser?.render
      ? components?.teaser?.render({
          defaultRender
        })
      : defaultRender
  }

  return (
    <Box
      bottom="0px"
      left="0px"
      position="fixed"
      sx={{
        '.chakra-popover__popper': {
          zIndex: 3
        }
      }}
      zIndex={3}>
      <GrandPopover
        borderRadius={16}
        {...{ isOpen }}
        contentProps={{
          maxWidth: isExpanded ? '62em' : '22em',
          width: 'calc(100vw - 44px)',
          outlineColor: 'none',
          shadow: 'xl',
          border: 'none',
          sx: {
            '.chakra-popover__body': {
              p: 0
            }
          }
        }}
        initialFocusRef={inputRef}
        placement="top-end"
        target={renderPopoverTarget()}
        title="">
        <Main
          inputRef={inputRef}
          onClose={onClose}
          paths={props.paths}
          settings={assistantSettings}
          components={components}
        />
      </GrandPopover>
    </Box>
  )
}
